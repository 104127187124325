<template>
  <!-- 企讯SCRM -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <!-- <div class="backgroundImg"> -->
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <!-- <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企讯SCRM</div>
            <div class="backgroundImg2">
              用企业微信营销拓客，社群营销，CRM管理，精细化运营私域流量
              客户引流，客户运营，客户管理，销售管理，客户关系更近，成交转化更多，企业业绩更好
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即咨询</span>
            </div>
          </div>
        </div> -->

        <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
            <img class="imgceshi" src="../assets/03产品-企讯SCRM/banner@2x.jpg" style="width:1920px;height:100%;object-fit:fill;">
        </div>
      </header>
    </div>
    
    <!-- <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;height:6900px;"> -->
    <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
        <img class="imgceshi" src="../assets/03产品-企讯SCRM/duanxin.jpg" style="width:1920px;height:100%;object-fit:fill;">
    </div>
    
    
    

    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag: 0,
      flag2: 0,
      Myswiper: null,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    that.Myswiper = new Swiper(".swiper-container", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      on: {
        transitionEnd: function () {
          that.flag = this.activeIndex;
        },
      },
    });
    that.Myswiper2 = new Swiper(".swiper-container2", {
      // autoplay: false,
      // direction : 'vertical',

      direction: "vertical",
      navigation: false,
      // autoplay: {
      //   delay: 3000,
      //   stopOnLastSlide: false,
      //   disableOnInteraction: false,
      // },
      // pagination: {
      //     el: '.swiper-pagination',
      // },
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
          // console.log(that.flag2);
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    change1() {
      this.flag = 0;
      this.Myswiper.slideTo(0, 1000, false);
    },
    change2() {
      this.flag = 1;
      this.Myswiper.slideTo(1, 1000, false);
    },
    change3() {
      this.flag = 2;
      this.Myswiper.slideTo(2, 1000, false);
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
    changesize(){
        eventBus.$emit('add',true)
    }
  },
  watch: {
    // $route(to,form) {
    //   console.log(to)
    //   console.log(form)
    //   this.$router.go(0)
    // }
  },
};
</script>

<style src="@/style/QiXunSCRM.css" scoped></style>
<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 530px;
  margin-top: 20px;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
